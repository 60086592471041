import React from "react"
import Layout from "../../components/layout"
import Logo from "../../img/client-joinem-logo.jpg"
import Cards from "../../img/client-joinem-cards.jpg"
import Web from "../../img/client-joinem-web.jpg"

export default ({ location, data }) => {
  return (
    <Layout location={location}>
      <div className="cover-heading-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <h1 className="cover-heading">Join'em</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="cover-wrapper cover-joinem">
        <div className="cover-wrapper-inner">
          <div className="cover-container">
            <div className="inner cover"></div>
          </div>
        </div>
      </div>
      <section className="m-t-100 sm-m-t-20">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="">
                <h4>Client</h4>
                <p className="m-b-20">Join'em LLC, NY</p>

                <h4>Roles</h4>
                <p className="m-b-20">Brand Identity / UI/UX Design</p>

                <p className="m-b-20">
                  <a rel="noopener" href="http://joinem.com" target="_blank">
                    joinem.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <p>
                Join'em makes it fun and easy to join forces with like-minded
                shoppers to lock in guaranteed savings off the lowest online
                prices on leading brands.
              </p>
              <p>
                They approached us with a desire to create a visual identity and
                user experience that speak to the millennials who love group
                buying.
              </p>
            </div>
          </div>

          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="Joinem logo" src={Logo} />
          </div>

          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="Screenshots" src={Cards} />
          </div>

          <div className="shot m-t-50 sm-m-t-20 m-b-50 sm-m-b-20">
            <img alt="Web screens" src={Web} />
          </div>
        </div>
      </section>
    </Layout>
  )
}
